<template>
    <div class="view">
        <img v-if="isWX" src="@/assets/rescueImgs/wx_yd.png" alt="" class="wx_yd" />
        <template v-else>
            <template v-for="(item, index) in appList">
                <div :class="['li', index == appList.length-1 ? 'border_none' : '']" 
                    v-if="source == item.source && (type == 0 || type == 1 ? item.type == type : true)"
                    :key="index">
                    <img :src="item.logo" alt="" class="logo">
                    <div class="right">
                        <div class="info">
                            <div class="name">{{ item.name }}</div>
                            <div class="btn" @click="downLoad(item)">安装</div>
                        </div>
                        <div class="tips">{{ item.tips }}</div>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>
<script>
import { isWeiXin, getQueryString } from '@/utils/index';
import { appDownload } from '@/apis/unicloud/api';
export default {
    data(){
        return {
            isWX: false,
            source: 'tc',
            type: '99',        //0：只显示技师端  1：只显示商家端
            appList: [
                {
                    appid: '__UNI__ED93B28',
                    name: '同城救援技师端',
                    logo: require('@/assets/logo/tc_logo1.png'),
                    tips: '用于技师接单',
                    source: 'tc',
                    iosLink: 'https://itunes.apple.com/cn/app/qq/id1673205847',
                    type: 0
                },
                {
                    appid: '__UNI__D81101A',
                    name: '同城救援商家端',
                    logo: require('@/assets/logo/tc_logo2.png'),
                    tips: '用于服务商调派订单',
                    source: 'tc',
                    iosLink: 'https://apps.apple.com/cn/app/同城救援商家端/id6478648810',
                    type: 1
                },
                {
                    appid: '__UNI__159D1F4',
                    name: '飞天鱼技师端',
                    logo: require('@/assets/logo/fty_logo.png'),
                    tips: '用于技师接单',
                    source: 'fty',
                    iosLink: '',
                    type: 0
                },
                {
                    appid: '__UNI__E746D90',
                    name: '飞天鱼商家端',
                    logo: require('@/assets/logo/fty_logo.png'),
                    tips: '用于服务商调派订单',
                    source: 'fty',
                    iosLink: '',
                    type: 1
                },
                {
                    appid: '__UNI__F86FA9D',
                    name: '通平救援技师端',
                    logo: require('@/assets/logo/tp_logo.png'),
                    tips: '用于技师接单',
                    source: 'tp',
                    iosLink: '',
                    type: 0
                },
                {
                    appid: '__UNI__9D7A833',
                    name: '通平救援商家端',
                    logo: require('@/assets/logo/tp_logo.png'),
                    tips: '用于服务商调派订单',
                    source: 'tp',
                    iosLink: '',
                    type: 1
                },
                {
                    appid: '__UNI__6F66972',
                    name: '救援云技师端',
                    logo: require('@/assets/logo/public_logo.png'),
                    tips: '用于技师接单',
                    source: 'public',
                    iosLink: '',
                    type: 0
                },
                {
                    appid: '__UNI__236A8F7',
                    name: '救援云商家端',
                    logo: require('@/assets/logo/public_logo.png'),
                    tips: '用于服务商调派订单',
                    source: 'public',
                    iosLink: '',
                    type: 1
                },
                {
                    appid: '__UNI__44AAEC0',
                    name: '车车通师傅端',
                    logo: require('@/assets/logo/cct_logo.png'),
                    tips: '用于技师接单',
                    source: 'cct',
                    iosLink: '',
                    type: 0
                },
                {
                    appid: '__UNI__FFCD6E2',
                    name: '车车通商家端',
                    logo: require('@/assets/logo/cct_logo.png'),
                    tips: '用于服务商调派订单',
                    source: 'cct',
                    iosLink: '',
                    type: 1
                },
                {
                    appid: '__UNI__BC2C0F9',
                    name: '救援师傅技师端',
                    logo: require('@/assets/logo/onekey_logo.png'),
                    tips: '用于技师接单',
                    source: 'yijian',
                    iosLink: '',
                    type: 0
                },
                {
                    appid: '__UNI__9888049',
                    name: '救援师傅商家端',
                    logo: require('@/assets/logo/onekey_logo.png'),
                    tips: '用于服务商调派订单',
                    source: 'yijian',
                    iosLink: '',
                    type: 1
                },
                {
                    appid: '__UNI__0DA6EFD',
                    name: '粤信救援',
                    logo: require('@/assets/logo/yx-logo.png'),
                    tips: '用于技师接单',
                    source: 'yx',
                    iosLink: '',
                    type: 0
                },
                {
                    appid: '__UNI__C9A04D2',
                    name: '粤信商家端',
                    logo: require('@/assets/logo/yx-m-logo.png'),
                    tips: '用于服务商调派订单',
                    source: 'yx',
                    iosLink: '',
                    type: 1
                },
            ]
        }
    },
    created(){
        let BASEAPI = process.env.VUE_APP_BASE_API;
        let type = getQueryString('type');
        let source = getQueryString('source');
        console.log(source, 'source')
        switch(BASEAPI){
            case 'https://v2.api.ftysos.com':
                source = 'fty'
                break;
            case 'https://v1.api.hntp614.cn':
                source = 'tp'
                break;
            case 'https://v1.api.yijianjiuyuan.com':
                source = 'yijian'
                break;
            case 'https://api.v2.tc-rescue.com':
                source = source ? source : 'public'
                break;
            default:
                source = 'tc'
                break
        }
        if(source){
            this.source = source;
        }
        if(type){
            this.type = type;
        }
        if(isWeiXin()){
            this.isWX = true;
        }
    },
    methods: {
        downLoad(e){
            let ran = navigator.userAgent
            let isAndroid = ran.indexOf('Android') > -1 || ran.indexOf('Linux') > -1
            let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            let appid = e.appid;
            if(isAndroid){
                appDownload({appid}).then((res)=>{
                    if(res.data.Code != 0 && res.data.data){
                        let url = res.data.data;
                        window.location.href = url;
                    }else{
                        this.$notify({
                            type: 'danger',
                            message: res.data.msg
                        });
                    }
                })
            }
            if(isIOS){
                if(e.iosLink){
                    window.location.href = e.iosLink;
                }else{
                    this.$notify({
                        type: 'danger',
                        message: '暂不支持！'
                    });
                }
            }
        },
    }
}
</script>

<style lang='scss' scoped>
    .li{
        display: flex;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 1px solid #e4e4e4;
        .logo{
            width: 54px;
            height: 54px;
            margin-right: 12px;
        }
        .right{
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .info{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .name{
                    font-size: 15px;
                }
                .btn{
                    background: #357EFE;
                    padding: 4px 10px;
                    box-sizing: border-box;
                    color: #fff;
                    font-size: 14px;
                    border-radius: 10px;
                }
            }
            .tips{
                color: #999;
                font-size: 14px;
            }
        }
    }
    .border_none{
        border: none;
    }
    .wx_yd{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
    }
</style>
